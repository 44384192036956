import React from "react";
import Addresschart from "./Charts/addresschart";
import Transactionchart from "./Charts/Transactionchart";
import BlockDatachart from "./Charts/blockDatachart";
import Tokenchartdata from "./Charts/Tokenchartdata";
import TokenDeploychart from "./Charts/TokenDeploychart";
import ContractDeploychart from './Charts/ContractDeploychart';

const Parent = () => {
  return (
    <div>
      <h1>Charts & stats</h1>
      <Transactionchart />
      <Addresschart />
      <BlockDatachart />
      <Tokenchartdata />
      <TokenDeploychart />
      <ContractDeploychart />

    </div>
  );
};

export default Parent;

import React, { useEffect, useState } from "react";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import "./Chart.css";

// ✅ Set default chart styles
defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.color = "black";

const Tokenchartdata = () => {
  const [loading, setLoading] = useState(true);
  const [contractChartData, setContractChartData] = useState([]);

  // ✅ Fetch and sort Contract Chart Data
  useEffect(() => {
    fetch("https://rubyexplorer.com/getContractchartdata")
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          const sortedData = [...data.result].sort((a, b) => new Date(a._id) - new Date(b._id));
          setContractChartData(sortedData);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching contract data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="TransactionsPerHour">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="TransactionsPerHour-content">
          <div className="TransactionsPerHour-dataCard TransactionsPerHour-revenueCard">
            <Line
              data={{
                labels: contractChartData.map((data) => data._id), // Dates
                datasets: [
                  {
                    label: "Contract Transactions",
                    data: contractChartData.map((data) => data.count), // Transaction counts
                    backgroundColor: "rgba(176, 161, 25, 0.5)",
                    borderColor: "rgba(60, 26, 145, 0.5)",
                  },
                ],
              }}
              options={{
                elements: {
                  line: {
                    tension: 0.5,
                  },
                },
                plugins: {
                  title: {
                    display: true,
                    text: "Contract Transactions Over Time",
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};


export default Tokenchartdata;
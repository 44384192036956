import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../Styles/TransactionDetail.css"; // Import your CSS file for styling
import success from "../images/success.svg";
import flash from "../images/flash.png"; // Make sure to update the path to your logo
import addresspng1 from "../images/address1.png";
import info from "../images/info.png";
import balancelogo from "../images/balancelogo.png";
import Search from "./Searchbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const TransactionDetail = () => {
  const { hash } = useParams();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState(null);
  const [activeTab, setActiveTab] = useState("Details");
  const [traceData, setTraceData] = useState(null);
  const [name, setName] = useState("RUBY");

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await fetch(
          `https://rubyexplorer.com/getTransactionData/${hash}`
        );
        const data = await response.json();
        setTransactionData(data.result);
        setName(data.name);
        setTraceData([
          {
            action: {
              callType: "call",
              from: data.result.from,
              gas: data.result.gas.toFixed(18),
              input: "R",
              to: data.result.to,
              value: data.result.value,
            },
            result: {
              gasUsed: data.result.fee,
              output: "0x",
            },
            subtraces: 0,
            traceAddress: [],
            type: "call",
          },
        ]);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    fetchTransactionData();
  }, [hash]);

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-4)}`;
  };

  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(2);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  const handleBlockClick = (blockNumber) => {
    navigate(`/Block/${blockNumber}`);
  };

  if (!transactionData) {
    return <div>Loading...</div>;
  }
  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
    toast.success("copied to clipboard!");

  };
  return (
    <div className="transaction-detail-container">
      <Search />
      <div className="tnxheading">Transaction Detail</div>
      <div className="txnheading2">
        <img src={flash} alt="flash logo" className="flash-logo" />
        <div className="txn-info">
          <span style={{ color: "#718096", marginRight: "10px" }}>
            {transactionData?.transctiontype === 3
              ? "Contract Call"
              : "Transfer"}
          </span>
          <span>
            {transactionData?.transctiontype === 3 ||
            transactionData?.transctiontype === 0
              ? `${trimValue(transactionData.value)} RUBY`
              : transactionData?.transctiontype === 2
              ? `${trimValue(transactionData.value)} TOKEN`
              : ""}
          </span>
          <span style={{ color: "#718096", marginLeft: "10px" }}> to </span>
          <img src={addresspng1} alt="addresspng logo" className="hashlogo" />
          <div className="txnaddress">{transactionData.to}</div>
          <div className="txnaddress1">
            {shortenAddress(10, transactionData.to)}
          </div>
        </div>
      </div>
      <div className="transaction-tabs">
        {["Details", "Token transfers", "Internal txns", "Raw trace"].map(
          (tab) => (
            <button
              key={tab}
              className={`transaction-button ${
                activeTab === tab ? "active" : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          )
        )}
      </div>

      <div className="transaction-detail">
        {activeTab === "Details" && (
          <>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              Transaction hash
            </span>
            <span className="text-hash"><span>{transactionData.hash}</span>
            <span onClick={() => copyToClipboard(transactionData.hash)} className="copy-icon" title="Copy From">&#x2398;</span>
            </span>
            <span className="text-hash1">
              <span> {shortenAddress(28, transactionData.hash)}</span>
              <span onClick={() => copyToClipboard(transactionData.hash)} className="copy-icon" title="Copy From">&#x2398;</span>
            </span>
          
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              Status and method
            </span>
            <span className="text-value">
              {" "}
              <img src={success} alt="miner logo" className="infologo" />
              {"Success"}
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              Block
            </span>
            <span
              className="text-value"
              style={{ color: "#1381bd", cursor: "pointer" }}
              onClick={() => handleBlockClick(transactionData.blockNumber)}
            >
              {" "}
              {transactionData.blockNumber}
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              Timestamp
            </span>
            <span className="text-value">{transactionData.createdAt}</span>
            <hr style={{ margin: "20px 0", color: "lightblue" }} />{" "}
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              From
            </span>
            <span
              className="text-value"
              style={{ color: "#1381bd", cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/address/${
                    transactionData.transctiontype === 3
                      ? transactionData.contract
                      : transactionData.from
                  }`
                )
              }
            >
              <img src={addresspng1} alt="miner logo" className="infologo" />
              <span className="transaction-to">
                {transactionData.transctiontype === 3
                  ? transactionData.contract
                  : transactionData.from}
              </span>
            </span>
            <span className="transaction-to1">
              {" "}
              {shortenAddress(
                20,
                transactionData.transctiontype === 3
                  ? transactionData.contract
                  : transactionData.from
              )}
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              TO
            </span>
            <span
              className="text-value"
              style={{ color: "#1381bd", cursor: "pointer" }}
              onClick={() => navigate(`/address/${transactionData.to}`)}
            >
              <img src={addresspng1} alt="miner logo" className="infologo" />
              <span className="transaction-to">{transactionData.to}</span>
            </span>
            <span className="transaction-to1">
              {" "}
              {shortenAddress(20, transactionData.to)}
            </span>
            <hr style={{ margin: "20px 0", color: "lightblue" }} />{" "}
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              Value
            </span>
            <span className="text-value">
              {" "}
              <img src={balancelogo} alt="miner logo" className="infologo" />
              {transactionData.value} {name}
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              Transaction fee
            </span>
            <span className="text-value">{transactionData.fee} RUBY</span>
            <div className="detail-row"></div>
            {/* Horizontal line */}
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              Gas
            </span>
            <span className="text-value">
              {transactionData.gas.toFixed(18)} RUBY
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              Gas Price
            </span>
            <span className="text-value">
              {transactionData.gasPrice.toFixed(18)} RUBY
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              Gas usage
            </span>
            <span className="text-value">
              {transactionData.gasUsed.toFixed(18)} RUBY
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              Nonce
            </span>
            <span className="text-value">{transactionData.nonce}</span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo" />
              Transaction Index
            </span>
            <span className="text-value">
              {transactionData.transactionIndex}
            </span>
          </>
        )}

        {/* Add similar conditional rendering blocks for each tab's content */}
        {activeTab === "Token transfers" && (
          <div className="Token-transfers">There are no token transfers. </div>
        )}
        {activeTab === "User operations" && (
          <div className="User-operations">There are no user operations.</div>
        )}
        {activeTab === "Internal txns" && (
          <div className="Internal-txns">
            There are no internal transactions for this transaction.
          </div>
        )}

        {activeTab === "State" && <div>{/* State content */}</div>}
        {activeTab === "Raw trace" && traceData && (
          <div className="trace-content">
            {JSON.stringify(traceData, null, 2)}
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionDetail;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col } from "react-bootstrap";
import success from "../images/success.svg";
import transactionlogo from "../images/transaction.png";
import Block from "../images/Block.png";
import arrow from "../images/arrow.png";
import addresspng from "../images/address.png";
import addresspng1 from "../images/address1.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Contractchartdatahome from './Charts/Contractchartdatahome';

const TransactionTable = () => {
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`https://rubyexplorer.com/getTokenTransactiondata`);
        const data = await response.json();
        setTransactions(data.contract);
      } catch (error) {
        console.error(error);
      }
    };
    fetchTransactions();
  }, []);



  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-6)}`;
  };

  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };

  const formatTimeAgoblock = (timestamp) => {
    let currentTime = Math.floor(Date.now() / 1000);
    let times = currentTime - timestamp;
    return `${times}s ago`;
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
    toast.success("copied to clipboard!");
  };

  const handleTransactionClick = (hash) => {
    navigate(`/transaction/${hash}`);
  };

  const handleallTransactionClick = () => {
    navigate(`/Alltransactions`);
  };

  const handleBlockClick = (blockNumber) => {
    navigate(`/Block/${blockNumber}`);
  };

  const handleAllBlockClick = () => {
    navigate(`/AllBlock`);
  };

  const handleAlladdressClick = (address) => {
    navigate(`/address/${address}`);
  };

  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  return (
    <>
      <div>

        <Row className="transaction-container">
          <Col md={7}>
            <h4 className="headingTransactions">Contract Transactions</h4>
            <div className="transaction-body">
              <Table table>
                <thead>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                {transactions.slice(0, 5).map((transaction, index) => (
                    <tr key={index}>
                      <td>
                        <div className='transaction-status'>
                          {transaction?.transctiontype === 0
                            ? 'Coin transfer'
                            : transaction?.transctiontype === 1
                              ? 'Contract Create'
                              : transaction?.transctiontype === 2
                                ? 'Token transfer'
                                : 'Contract Call'}
                        </div>
                        <div className='transaction-status1'>
                          success
                        </div>
                        <div >
                          <span className='transaction-hash' onClick={() => handleTransactionClick(transaction.hash)} style={{ cursor: "pointer" }}>
                            <img src={transactionlogo} alt="block logo" className="transactionlogo" />
                            {shortenAddress(6, transaction.hash)}
                          </span>
                          <span onClick={() => copyToClipboard(transaction.hash)} className="copy-icon" title="Copy From">&#x2398;</span>
                        </div>


                      </td>

                      <td>

                        <span className='transaction-time'>
                          {formatTimeAgo(transaction.createdAt)}
                        </span>

                      </td>
                      <td>
                        <div className='transactionblock-number'>{transaction.blockNumber}</div>

                      </td>

                      <td>
                        <div className="transaction-twoaddress">

                          <img src={addresspng} alt="Transaction logo" className="Alltransaction-address-logo" />{" "}
                          <div className="Alltransaction-address">
                            <span
                              onClick={() => handleAlladdressClick(transaction.transctiontype === 3 ? transaction.contract : transaction.from)}
                              style={{ cursor: "pointer" }}
                            >
                              {shortenAddress(5, transaction.transctiontype === 3 ? transaction.contract : transaction.from)}
                            </span>

                            <span onClick={() => copyToClipboard(transaction.from)} className="copy-icon" title="Copy From">&#x2398;</span>
                          </div>
                          <img src={addresspng1} alt="Transaction logo" className="Alltransaction-address-logo1" />{" "}
                          <div className="Alltransaction-address1">
                            <span onClick={() => handleAlladdressClick(transaction.to)} style={{ cursor: "pointer" }}>
                              {shortenAddress(5, transaction?.to === '0' ? transaction.contract : transaction.to)}
                            </span>
                            <span onClick={() => copyToClipboard(transaction.to)} className="copy-icon" title="Copy To">&#x2398;</span>
                          </div>
                        </div>
                      </td>
                      <td><div>
                        <div className='transaction-value'> {trimValue(transaction.value)} Value</div>
                        <div className='transaction-fee'> {trimValue(transaction.fee)} Fee</div>
                      </div></td>

                      <td>

                      </td>
                    </tr>
                  ))}
                  <hr style={{ margin: "20px 0", color: "lightblue" }} />{" "}

                  <div className="alltransactions" onClick={handleallTransactionClick}>
                    View all transactions
                  </div>
                </tbody>
              </Table>

            </div>

            <div className="Topaccounts-cards">
              <Table table>
                <tbody>
                {transactions.slice(0, 5).map((transaction, index) => (
                    <tr key={transaction.address} style={{ cursor: "pointer" }}>
                      <td>
                        <div>
                          <img src={transactionlogo} alt="block logo" className="Topaccounts-address-logo-mobile" />

                          <div className='Topaccounts-address-mobile ' onClick={() => handleTransactionClick(transaction.hash)}>{shortenAddress(15, transaction.hash)}
                          </div>


                          <div className='Topaccounts-time'>
                            {formatTimeAgo(transaction.createdAt)}
                          </div>

                          <img src={addresspng} alt="block logo" className="Topaccounts-address-logo-mobile" />

                          <div className='Topaccounts-address-mobile' onClick={() => handleAlladdressClick(transaction.transctiontype === 3 ? transaction.contract : transaction.from)}
                            style={{ cursor: "pointer" }}>{shortenAddress(5, transaction.transctiontype === 3 ? transaction.contract : transaction.from)}

                          </div>



                          <img src={arrow} alt="Transaction logo" className="Topaccounts-arror-logo1" />{" "}
                          <img src={addresspng1} alt="block logo" className="Topaccounts-address1-logo-mobile" />
                          <div className='Topaccounts-address1-mobile' onClick={() => handleAlladdressClick(transaction.to)} style={{ cursor: "pointer" }} >{shortenAddress(5, transaction.to)}

                          </div>
                          <div onClick={() => copyToClipboard(transaction.from)} className="from-icon" title="Copy From">&#x2398;</div>
                          <div onClick={() => copyToClipboard(transaction.to)} className="to-icon" title="Copy From">&#x2398;</div>
                          <br />

                          <div className="Topaccounts-data-mobile">Value {transaction.value}</div>
                          <br />
                          <div className="Topaccounts-data-mobile">Fee {transaction.fee}</div>
                          <div className='Topaccounts-time'>
                            {transaction?.transctiontype === 0
                              ? 'Coin transfer'
                              : transaction?.transctiontype === 1
                                ? 'Contract Create'
                                : transaction?.transctiontype === 2
                                  ? 'Token transfer'
                                  : 'Contract Call'}
                          </div>
                        </div>
                      </td>
                      <hr style={{ margin: "20px 0", color: "lightblue" }} />{" "}
                    </tr>
                  ))}
                  <div className="alltransactions" onClick={handleallTransactionClick}>
                    View all transactions
                  </div>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={5}>
            <Contractchartdatahome />
          </Col>
        </Row>


      </div>

    </>
  );
};

export default TransactionTable;

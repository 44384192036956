import React, { useEffect, useState } from "react";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";

import "./Chart.css";

// ✅ Set default chart styles
defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.color = "black";

const Transactionchartdata = () => {
  const [loading, setLoading] = useState(true);
  const [addressData, setaddressData] = useState([]);
    useEffect(() => {
      fetch("https://rubyexplorer.com/getTopaccounts/1/30")
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            setaddressData(data.result);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching transaction data:", error);
          setLoading(false);
        });
    }, []);

     

  return (
    <div className="Transactionchart">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
        
          
           <div className="dataCard customerCard">
                    <Bar
                      data={{
                        labels: addressData.map((data) => data.address), // ✅ Replaces addresses with numbers
                        datasets: [
                          {
                            label: "Balance",
                            data: addressData.map((data) => data.balance),
                            backgroundColor: "rgba(24, 111, 141, 0.8)",
                            borderRadius: 5,
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          title: {
                            text: "Top Account Balances",
                          },
                          tooltip: {
                            callbacks: {
                              label: (tooltipItem) => {
                                return `Balance: ${tooltipItem.raw.toLocaleString()}`; // ✅ Only shows balance
                              },
                            },
                          },
                        },
                        scales: {
                          x: {
                            ticks: {
                              display: false, // ✅ Hides X-axis labels (No Address)
                            },
                            grid: {
                              drawTicks: false, // ✅ Removes small ticks on X-axis
                            },
                          },
                          // y: {
                          //   title: {
                          //     display: true,
                          //     text: "Balance",
                          //   },
                          // },
                        },
                      }}
                    />
                  </div>

                
                  
        </>
      )}
    </div>
  );
};
export default Transactionchartdata;

import React, { useState } from "react";
import "../Styles/Searchbar.css"; // Import your CSS file for styling
import search from "../images/search.png"; // Ensure correct image path
import { useNavigate } from "react-router-dom";

const Search = () => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = async () => {
    if (!query) return;

    try {
      console.log("Searching for:", query);
      const response = await fetch(`https://rubyexplorer.com/searchdata/${query}`);

      console.log("Response Status:", response.status);

      if (!response.ok) {
        console.warn("Invalid input: Navigating to error page.");
        navigate("/Invalidinput");
        return;
      }

      const data = await response.json();
      console.log("API Response:", data);

      if (!data.result) {
        console.error("Unexpected API response format:", data);
        navigate("/Invalidinput");
        return;
      }

      // Normalize result by trimming and converting to lowercase
      const resultType = (data.result || "").trim().toLowerCase();
      navigateByResult(resultType, query);
    } catch (error) {
      console.error("Error fetching search data:", error);
      navigate("/Invalidinput");
    }
  };

  const navigateByResult = (resultType, searchQuery) => {
    console.log("Navigating based on result:", resultType);

    switch (resultType) {
      case "transaction":
        navigate(`/transaction/${searchQuery}`);
        break;
      case "alladdress":
        navigate(`/address/${searchQuery}`);
        break;
      case "block":
        navigate(`/block/${searchQuery}`);
        break;
      case "token":
        navigate(`/TokenDetail/${searchQuery}`);
        break;
      case "contract":
        navigate(`/contractDetail/${searchQuery}`);
        break;
      default:
        console.warn("Unknown result type, navigating to error page.");
        navigate("/Invalidinput");
    }
  };

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-6)}`;
  };

  return (
    <div>
      <form
        className="search-txn"
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <input
          type="text"
          placeholder="Search by address / txn hash / block / token..."
          className="search-input"
          style={{
            backgroundImage: `url(${search})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "10px center",
            paddingLeft: "30px",
            width: "100%",
          }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </form>

      <div className="search-results">
        {query && (
          <p
            className="search-query"
            onClick={handleSearch}
            style={{ cursor: "pointer", fontSize: "20px", fontWeight: "bold", paddingTop: "20px" }}
          >
            <div className="search-data-mobile">
              Address/hash
              <br />
              {shortenAddress(15, query)}
            </div>
            <div className="search-data">
              Address/hash
              <br />
              {query}
            </div>
          </p>
        )}
      </div>
    </div>
  );
};

export default Search;

import React, { useEffect, useState } from "react";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";

import "./Chart.css";

// ✅ Set default chart styles
defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.color = "black";

const Tokenchartdata = () => {
  const [loading, setLoading] = useState(true);
  const [Tokenchartdata, setTokenchartdata] = useState([]);
  const [Contractchartdata, setContractchartdata] = useState([]);

  useEffect(() => {
    fetch("https://rubyexplorer.com/getTokenchartdata")
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          console.log(data.result)
          setTokenchartdata(data.result);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching transaction data:", error);
        setLoading(false);
      });
  }, []);




  useEffect(() => {
    fetch("https://rubyexplorer.com/getContractchartdata")
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setContractchartdata(data.result);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching transaction data:", error);
        setLoading(false);
      });
  }, []);


  return (
    <div className="Transactionchart">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
        <div className="dataCard revenueCard">
                    <Line
                      data={{
                        labels: Contractchartdata.map((data) => data.name.toUpperCase()),
                        datasets: [
                          {
                            label: "Total value",
                            data: Contractchartdata.map((data) => data.value),
                            backgroundColor: "rgba(176, 161, 25, 0.5)",
                            borderColor: "rgba(60, 26, 145, 0.5)",
                          },
                        ],
                      }}
                      options={{
                        elements: {
                          line: {
                            tension: 0.5,
                          },
                        },
                        plugins: {
                          title: {
                            text: "Latest Contract Transaction",
                          },
                        },
                      }}
                    />
                  </div>

          <div className="dataCard customerCard">
            <Bar
              data={{
                labels: Tokenchartdata.map((data) => data.name),
                datasets: [
                  {
                    label: "Token Transaction",
                    data: Tokenchartdata.map((data) => data.value),
                    backgroundColor: "rgba(144, 36, 126, 0.8)",
                    borderRadius: 5,
                  },
                ],
              }}
              options={{
                plugins: {
                  title: {
                    text: "Latest Token Transaction",
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        return `Value: ${tooltipItem.raw.toLocaleString()}`; // ✅ Only shows balance
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      display: true, // ✅ Hides X-axis labels (No Address)
                    },
                    grid: {
                      drawTicks: true, // ✅ Removes small ticks on X-axis
                    },
                  },
                  // y: {
                  //   title: {
                  //     display: true,
                  //     text: "Transaction",
                  //   },
                  // },
                },
              }}
            />
          </div>

        </>
      )}
    </div>
  );
};
export default Tokenchartdata;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './component/Header';
import Card from './component/Card';
import GridPage from './component/GridPage';
import TransactionGraph from './component/TransactionGraph';
import TransactionTable from './component/TransactionTable';
import Footer from './component/Footer';
import TransactionDetail from './component/TransactionDetail';
import Alltransactions from './component/Alltransactions';
import Block from './component/Block';
import Allblock from './component/Allblock';
import Alladdress from './component/Alladdress';
import Signup from './component/Signup';
import Account from './component/Account';
import Dashboard from './component/Dashboard';
import Topaccounts from './component/Topaccounts';
import Tokens from './component/Tokens';
import VerifiedRuby from './component/VerifiedRuby';
import IssueForm from './component/IssueForm';
import Featurerequest from './component/Featurerequest';
import Invalidinput from './component/Invalidinput';
import Wallet from './component/Wallet';
import Api from './component/Api';
import TokenDetail from './component/TokenDetail';
import Deploycontract from './component/Deploycontract';
import ContractDetail from './component/ContractDetail';
import TermsAndConditions from './component/TermsAndConditions';
import PrivacyPolicy from './component/PrivacyPolicy';
import RubyWeb3Docs from './component/RubyWeb3Docs';
import SearchToken from './component/SearchToken';
import Chart from './component/Chartsstats';
import ContractTransactionTable from './component/ContractTransactionTable';
import TokenContractdeploy from './component/TokenContractdeploy';

import { ToastContainer } from 'react-toastify'; // Import the container
import 'react-toastify/dist/ReactToastify.css'; // Import the styles

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import TokenTransactionTable from './component/TokenTransactionTable';
import Contractdeploy from './component/Contractdeploy';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<>
            <Card />
            <GridPage />
            <TransactionGraph />
            <TransactionTable />
          </>} />
          <Route path="/transaction/:hash" element={<TransactionDetail />} />
          <Route path="/address/:address" element={<Alladdress />} />
          <Route path="/Alltransactions" element={<Alltransactions />} />
          <Route path="/Allblock" element={<Allblock />} />
          <Route path="/Block/:blockNumber" element={<Block />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Account" element={<Account />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Wallet/:email" element={<Wallet />} />
          <Route path="/Account" element={<Account />} />
          <Route path="/Topaccounts" element={<Topaccounts />} />
          <Route path="/Tokens" element={<Tokens />} />
          <Route path="/VerifiedRuby" element={<VerifiedRuby />} />
          <Route path="/IssueForm" element={<IssueForm />} />
          <Route path="/Featurerequest" element={<Featurerequest />} />
          <Route path="/Invalidinput" element={<Invalidinput />} />
          <Route path="/Api" element={<Api />} />
          <Route path="/TokenDetail/:contractAddress" element={<TokenDetail />} />
          <Route path="/ContractDetail/:contractAddress" element={<ContractDetail />} />

          <Route path="/Deploycontract" element={<Deploycontract />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />

          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/rubyWeb3Docs" element={<RubyWeb3Docs />} />
          <Route path="/SearchToken" element={<SearchToken />} />
          <Route path="/Chart" element={<Chart />} />

          <Route path="/aa" element={<>
            <Card />
            <GridPage />
            <TransactionGraph />
            <TransactionTable />
            <TokenTransactionTable />
            <ContractTransactionTable />
            <TokenContractdeploy />

            <Contractdeploy />

          </>} />
        </Routes>
        <Footer />
        <ToastContainer /> {/* Place the ToastContainer at the root */}
      </div>
    </Router>
  );
}

export default App;

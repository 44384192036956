import { useState, useEffect } from "react";

const Dropdown = ({ address, selected, setSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!address) return; // Prevent API call if no address

    const fetchBlocks = async () => {
      try {
        const response = await fetch(
          `https://rubyexplorer.com/getallTokenBalance/${address}`
        );
        const data = await response.json();

        if (data.result && Array.isArray(data.result)) {
          setOptions(data.result); // Store array in state
        } else {
          setOptions([]); // Handle unexpected response
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOptions([]); // Reset on error
      }
    };

    fetchBlocks();
  }, [address]);

  return (
    <div className="relative inline-block text-left w-64">
      <button
        onClick={() => setIsOpen(!isOpen)}
        style={{
          color: "#228313",
          cursor: "pointer",
          backgroundColor: "#86bc8f",
          paddingLeft: "50px",
          paddingRight: "50px",
          borderRadius: "50px",
          marginBottom: "4rem",
        }}
      >
        {selected
          ? `${selected.name} (${selected.contractAddress.slice(0, 6)}...)`
          : "Select Token"}
      </button>

      {isOpen && options.length > 0 && (
        <div className="absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto z-10">
          {options.map((option, index) => (
            <div key={index}>
              <div
                onClick={() => {
                  setSelected(option); // Updates the selected token
                  setIsOpen(false); // Close the dropdown
                }}
                className="px-4 py-3 cursor-pointer hover:bg-blue-100 transition duration-200"
              >
                <p className="text-gray-500 text-xs">
                  {option.name} {option.contractAddress}
                </p>
              </div>
              {index !== options.length - 1 && (
                <hr className="border-gray-200" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

import React, { useEffect, useState } from "react";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";

import "./Chart.css";

// ✅ Set default chart styles
defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.color = "black";

const Transactionchartdata = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addressData, setaddressData] = useState([]);
  const [blockData, setBlockData] = useState([]);

  useEffect(() => {
    fetch("https://rubyexplorer.com/getTransactionGraph")
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setTransactionData(data.result);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching transaction data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="Transactionchart">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {/* ✅ Line Chart - Transaction Count & Fees */}
          <div className="dataCard revenueCard">
            <Line
              data={{
                labels: transactionData.map((data) => `Hour ${data._id}`),
                datasets: [
                  {
                    label: "Transaction Count",
                    data: transactionData.map((data) => data.count),
                    backgroundColor: "rgba(6, 79, 240, 0.5)",
                    borderColor: "#064FF0",
                  },
                  {
                    label: "Total Fee",
                    data: transactionData.map((data) => data.totalFee),
                    backgroundColor: "rgba(255, 48, 48, 0.5)",
                    borderColor: "#FF3030",
                  },
                ],
              }}
              options={{
                elements: {
                  line: {
                    tension: 0.5,
                  },
                },
                plugins: {
                  title: {
                    text: "Transaction Count & Fees per Hour",
                  },
                },
              }}
            />
          </div>

          {/* ✅ Bar Chart - Transaction Count */}
          <div className="dataCard customerCard">
            <Bar
              data={{
                labels: transactionData.map((data) => `Hour ${data._id}`),
                datasets: [
                  {
                    label: "Transaction Count",
                    data: transactionData.map((data) => data.count),
                    backgroundColor: "rgba(15, 71, 81, 0.8)",
                    borderRadius: 5,
                  },
                ],
              }}
              options={{
                plugins: {
                  title: {
                    text: "Transactions Per Hour",
                  },
                },
              }}
            />
          </div>

          {/* ✅ Doughnut Chart - Transaction Fee Breakdown */}
          <div className="dataCard revenueCard">
            <Line
              data={{
                labels: transactionData.map((data) => `Hour ${data._id}`),
                datasets: [
                  {
                    label: "Total Fee",
                    data: transactionData.map((data) => data.totalFee),
                    backgroundColor: "rgba(255, 48, 48, 0.5)",
                    borderColor: "#FF3030",
                  },
                ],
              }}
              options={{
                elements: {
                  line: {
                    tension: 0.5,
                  },
                },
                plugins: {
                  title: {
                    text: "Transaction Fees per Hour",
                  },
                },
              }}
            />
          </div>
          <div className="dataCard categoryCard">
            <Doughnut
              data={{
                labels: transactionData.map(
                  (data) => `Hour ${data._id ?? "Unknown"}`
                ),
                datasets: [
                  {
                    label: "Total Fee",
                    data: transactionData.map((data) => data.totalFee),
                    backgroundColor: transactionData.map(
                      (_, i) =>
                        [
                          "rgba(43, 63, 229, 0.8)",
                          "rgba(250, 192, 19, 0.8)",
                          "rgba(253, 135, 135, 0.8)",
                        ][i % 3]
                    ),
                    borderColor: transactionData.map(
                      (_, i) =>
                        [
                          "rgba(43, 63, 229, 0.8)",
                          "rgba(250, 192, 19, 0.8)",
                          "rgba(253, 135, 135, 0.8)",
                        ][i % 3]
                    ),
                  },
                ],
              }}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Transaction Fee",
                    font: { size: 16 },
                  },
                  legend: {
                    display: true,
                    position: "right",
                    labels: {
                      boxWidth: 20,
                      font: { size: 12 },
                    },
                  },
                },
                layout: {
                  padding: {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10,
                  },
                },
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default Transactionchartdata;
